
import initialState from './initialState';
import {STUFF_RECEIVE, STUFF_UPDATE} from "../plugins/NTWys2/Components/actions/allTasks";

export default function stuff(state = initialState.stuff, action) {
    switch (action.type) {
        case STUFF_RECEIVE:
            let newState1 = {...state};
            newState1 = {...action.stuff};
            console.log('RECEIVE_STUFF Action')
            return newState1;
        case STUFF_UPDATE:
            console.log("state", state)
            console.log("jj", action.payload)
            let newState8 = {...state};
            newState8[0] = {...action.payload}
            return newState8;
        default:
            return state;
    }
}