import React, {Fragment} from 'react';
import {Col, Container, NavLink, Row} from "reactstrap";
import {openModal} from "./Modals/modalActions";
import * as userActions from "../actions/userActions";
import * as stuffActions from "../actions/stuffActions";
import * as taskActions from "../plugins/NTWys2/Components/actions/taskActions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {withRouter} from "react-router-dom";
import Footer from "./Footer";
import AuthHelperMethods from "./AuthHelperMethods";
import {prodDev} from "../library/prodDev";

async function getProductsNT(pagename) {
    try {
        let Auth = new AuthHelperMethods();
        let endURL = prodDev.getUrl()
        let urell = endURL + pagename
        const response = await Auth.fetch(urell)
        const json = await response;
        json.filename = pagename
        return json
    } catch (e) {
        console.log("no" + e)
    }
}

class DirectoryPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            data: null,
        }
    }

    async componentDidMount() {
        let indexpage = await getProductsNT("/getindex")
        this.setState({ page: indexpage } );
    }

    editPage = (thePage) => {
        this.props.history.replace(`/page/${thePage}`)
    }

    render() {

        const { page } = this.state;

        if (!page) {
            return null
        }

        return (
            <Fragment>
                <br/>
                <Container>
                    <Row>
                        <Col>
                            <h1>Welcome, Jon</h1>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col sm={9}>

                            <table className="table">
                                <thead className="thead-light">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Title</th>
                                    <th scope="col">Public/Private</th>
                                    <th scope="col">URL</th>
                                    <th scope="col">Options</th>
                                </tr>
                                </thead>
                                <tbody>

                                <Fragment>
                                    {
                                        page.pages.map((apage, index) => (
                                            <tr>
                                                <th scope="row">1</th>
                                                <td>{apage.title}</td>
                                                <td>public</td>
                                                <td>/ntdir</td>
                                                <td>
                                                    <NavLink className="font-weight-bold" onClick={() => {
                                                        this.editPage(apage.filename)
                                                    }} href="#">
                                                        <i className="far fa-pencil-alt"></i>
                                                        &nbsp;
                                                    </NavLink>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </Fragment>

                                </tbody>
                            </table>
                        </Col>
                        <Col sm={3}>
                            Easy Page Editing and updating, directly from your web browser.<br/><br/>
                            Visual drag and drop allows content to be placed and moved easily and quickly.<br/><br/>
                            Make your content available directly to the web with a couple of mouse clicks with your own shortcut URL.<br/><br/>
                        </Col>
                    </Row>
                    <br/>
                    <br/>
                    <br/>
                    <Footer/>
                    <br/>
                    <br/>
                    <br/>
                </Container>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    currentTasks: state.task,
    stuff: state.stuff,
});

function mapDispatchToProps(dispatch) {
    return {
        openModal: bindActionCreators(openModal, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
        stuffActions: bindActionCreators(stuffActions, dispatch),
        taskActions: bindActionCreators(taskActions, dispatch),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DirectoryPage));
