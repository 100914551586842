import React from 'react'
import {connect} from 'react-redux';
import {Formik} from "formik/dist/index";
import {saveColumn} from "../../plugins/NTWys2/Components/actions/taskActions";
import {closeModal} from "./modalActions";

class EditColumnComponent extends React.Component {

    /* This is the deliverable so task needs updating to deliverable */
    constructor(props) {
        super(props);
        this.state = {
            columnId: this.props.currentModals.modalProps.columnId,
            column: []
        };
    }

    async componentDidMount() {
        const aColumn = {
            columnId: this.props.currentTasks.columns[this.state.columnId].id,
            title: this.props.currentTasks.columns[this.state.columnId].title,
            taskIds: [...this.props.currentTasks.columns[this.state.columnId].taskIds]
        }

        this.setState({column: aColumn});
    }

    onDelete() {
        // this.props.dispatch(
        //     deleteDeliverable(
        //         this.state.deliverableId,
        //     ));
    }

    render() {

        if (this.state.column.length === 0) {
            return null
        }

        return (
            <div style={{width: '600px'}}>

                <Formik

                    initialValues={{
                        columnId: this.state.column.id,
                        title: this.state.column.title,
                    }}

                    onSubmit={values => {
                        console.log("-----", JSON.stringify(this.state))
                        this.props.dispatch(
                            saveColumn(
                                {
                                    id: this.state.columnId,
                                    title: values.title,
                                    taskIds: [...this.state.column.taskIds]
                                }
                            )
                        );
                        this.props.dispatch(closeModal())

                    }}

                    // validate={ values => {
                    //     let errors= {}
                    //     if (!values.title) {
                    //         errors.title = "please enter a name"
                    //     }
                    //     if (!values.duration) {
                    //         errors.duration= "please enter a duration"
                    //     }
                    //     return errors
                    // }}

                >
                    {
                        ({handleSubmit, handleChange, values, errors, handleBlur, touched}) => (
                            <form onSubmit={handleSubmit}>

                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Title</label>
                                    <input
                                        className="form-control"
                                        onChange={handleChange}
                                        value={values.title}
                                        type="text"
                                        name="title"
                                        placeholder="Enter your Title"
                                        onBlur={handleBlur}
                                        rows={15}
                                    />
                                    <small id="emailHelp" className="form-text text-muted">A nice title
                                    </small>
                                </div>

                                <div className="row">
                                    <div className="col-sm-6">
                                        <button type="submit" className="btn btn-primary pull-right">Save</button>
                                        &nbsp;
                                        <button onClick={() => {
                                            this.props.onCancel()
                                        }} className="btn btn-secondary pull-right">Cancel
                                        </button>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="text-right">
                                            <button type="button" className="btn btn-danger" onClick={() => {
                                                this.onDelete()
                                            }}>Delete
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {
                                    errors.title &&
                                    <div style={{color: 'red'}}>
                                        {errors.title}
                                    </div>
                                }
                            </form>
                        )

                    }
                </Formik>

            </div>
        )

    };

}

const mapStateToProps = (state) => ({
    currentModals: state.modals,
    currentTasks: state.task
});


export default connect(mapStateToProps, null)(EditColumnComponent);
