import React from 'react';
import { connect } from 'react-redux';
import TestModal from './TestModal';
import EditTaskModal from "./EditTaskModal";
import EditColumnModal from "./EditColumnModal";
import LoginModal from "./LoginModal";

const modalLookup = {
    TestModal,
    EditTaskModal,
    EditColumnModal,
    LoginModal,
};

const mapState = (state) => ({
    currentModal: state.modals
});

const ModalManager = ({currentModal}) => {
    console.log("modalmanager");
    let renderedModal;
    if (currentModal) {
        console.log("yes");
        const {modalType, modalProps} = currentModal;
        const ModalComponent = modalLookup[modalType];
        renderedModal = <ModalComponent {...modalProps} />
    }
    console.log("no");
    return <span>{renderedModal}</span>
};

export default connect(mapState)(ModalManager);