import {combineReducers} from 'redux';
import stuff from './stuffReducer';
import {reducer as formReducer} from 'redux-form';
import modalReducer from '../components/Modals/modalReducer';
import task from "./taskReducer";
import { reducer as toastrReducer } from 'react-redux-toastr';
import userReducer from "./userReducer";

const rootReducer = combineReducers({
    stuff,
    task,
    form: formReducer,
    modals: modalReducer,
    toastr: toastrReducer,
    user: userReducer
});

export default rootReducer;