import React, {Fragment} from 'react';
import './App.css';
import '@atlaskit/css-reset'
import {ThemeProvider} from 'styled-components'
import ModalManager from "./components/Modals/ModalManager";
import {connect} from "react-redux";
import NavBar from "./components/NavBar";
import {BrowserRouter, Route} from 'react-router-dom';
import HomePage from "./components/HomePage";
import AuthHelperMethods from "./components/AuthHelperMethods";
import DirectoryPage from "./components/DirectoryPage";
import EditPage from "./components/EditPage";

import "react-image-gallery/styles/css/image-gallery.css";
import Redirect from "react-router-dom/es/Redirect.js";

const theme = {
    lightShade: '#FFF',
    lightAccent: '#C1C9EC',
    mainBrand: '#608EEF',
    darkAccent: '#0e0c7d',

    darkShades: '#000019',
    darkShadesRGB: 'rgba(255, 255, 255, .8)',
    darkShadesRGBOne: 'rgba(255, 255, 255, .1)',
    darkShadesRGBClear: 'rgba(255, 255, 255, .5)',

    black: 'black'
};

class App extends React.Component {

    render() {
        const Auth = new AuthHelperMethods()

        if (!this.props.currentTasks) {
            return null
        }

        return (
            <Fragment>
                <BrowserRouter>
                    {/*<Route path="/pages" exact render={({location}) => (*/}
                    {/*    <ThemeProvider theme={theme}>*/}
                    {/*        <div>*/}
                    {/*            {document.body.style = 'background: unset;'}*/}
                    {/*            <ModalManager/>*/}
                    {/*            <NavBar/>*/}
                    {/*            <br/>*/}
                    {/*            <br/>*/}
                    {/*            <br/>*/}
                    {/*            <br/>*/}
                    {/*            <NTWys currentTasks={this.props.currentTasks} showEditor={showEditor}*/}
                    {/*                   bImagesOnly={true}/>*/}
                    {/*        </div>*/}
                    {/*    </ThemeProvider>*/}
                    {/*)}*/}
                    {/*/>*/}

                    <Route path="/" exact render={({location}) => (
                        <ThemeProvider theme={theme}>
                            <div>
                                <ModalManager/>

                                {!Auth.loggedIn() &&
                                    <Fragment>
                                        <HomePage/>
                                        {document.body.style = 'background: black;'}
                                    </Fragment>
                                }

                                {Auth.loggedIn() &&
                                    <Fragment>
                                        {document.body.style = 'background: unset;'}
                                        <NavBar/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <DirectoryPage/>
                                    </Fragment>
                                }
                            </div>

                        </ThemeProvider>
                    )}/>
                    <Route path="/page/:pagename" exact render={({match}) => (
                        <ThemeProvider theme={theme}>
                            <div>

                                {!Auth.loggedIn() &&
                                    <Redirect to="/"/>
                                }

                                {Auth.loggedIn() &&
                                    <Fragment>
                                        {document.body.style = 'background: unset;'}
                                        <EditPage matchParams={match}/>
                                    </Fragment>
                                }
                            </div>
                        </ThemeProvider>
                    )}/>
                </BrowserRouter>
            </Fragment>
        )

    }
}

const mapStateToProps = (state) => ({
    currentTasks: state.task,
    stuff: state.stuff,
});

export default connect(mapStateToProps)(App);
