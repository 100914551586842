
export const TASK_UPDATE= 'TASK_UPDATE';
export const TASKS_RECEIVE = 'TASKS_RECEIVE';
export const TASK_MOVE = 'TASK_MOVE';

export const TASK_ADDROW = 'TASK_ADDROW';
export const TASK_ADDCELL = 'TASK_ADDCELL';
export const TASK_DELETE = 'TASK_DELETE';

export const STUFF_UPDATE = 'STUFF_UPDATE';
export const STUFF_RECEIVE = 'STUFF_RECEIVE';

export const COLUMN_UPDATE = 'COLUMN_UPDATE';
export const COLUMN_RECEIVE = 'COLUMN_RECEIVE';
export const COLUMN_MOVE = 'COLUMN_MOVE';
