import {createStore, applyMiddleware, compose} from 'redux';
import allReducers from '../reducers/allReducers';
import thunk from 'redux-thunk';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore() {
    return createStore(
        allReducers,
        composeEnhancer(applyMiddleware(thunk)),
        );
}
