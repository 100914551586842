import initialState from '../initial-data';
import {
    COLUMN_UPDATE,
    TASK_ADDCELL,
    TASK_ADDROW,
    TASK_DELETE,
    TASK_MOVE,
    TASK_UPDATE,
    TASKS_RECEIVE
} from '../plugins/NTWys2/Components/actions/allTasks';
import {LoremIpsum} from "lorem-ipsum";

export default function task(state = initialState(), action) {

    switch (action.type) {

        case TASKS_RECEIVE:
            let newStates
            newStates = {...action.stuff};
            return newStates;

        case TASK_ADDROW:
            let newStatea = {...state};

            const uuidv4 = require('uuid/v4');
            let taskid = uuidv4();
            let coulmnid = uuidv4();

            const titlelorem = new LoremIpsum({
                sentencesPerParagraph: {
                    max: 8,
                    min: 4
                },
                wordsPerSentence: {
                    max: 7,
                    min: 3
                }
            });
            let theTitle = titlelorem.generateSentences(1);

            const lorem = new LoremIpsum({
                sentencesPerParagraph: {
                    max: 8,
                    min: 4
                },
                wordsPerSentence: {
                    max: 16,
                    min: 4
                }
            });
            lorem.suffix = '\n\n'
            let theContent = lorem.generateParagraphs(3);

            newStatea.tasks[taskid] = {
                id: taskid,
                type: "markdown",
                content: theContent
            }

            newStatea.columns[coulmnid] = {
                id: coulmnid,
                title: theTitle,
                taskIds: [taskid],
            }

            newStatea.columnOrder.push(coulmnid)

            return newStatea;

        case TASK_ADDCELL:
            let newStateb = {...state};

            const uuidv4ba = require('uuid/v4');
            let taskidb = uuidv4ba();

            const loremb = new LoremIpsum({
                sentencesPerParagraph: {
                    max: 8,
                    min: 4
                },
                wordsPerSentence: {
                    max: 16,
                    min: 4
                }
            });
            loremb.suffix = '\n\n'
            let theContentb = loremb.generateParagraphs(3);

            newStateb.tasks[taskidb] = {
                id: taskidb,
                type: "markdown",
                content: theContentb
            }

            newStateb.columns[action.columnId].taskIds.push(taskidb)

            return newStateb;

        case TASK_UPDATE:
            console.log("action", action.payload)
            let newState8 = {...state};
            newState8.tasks[action.payload.id] = {...action.payload};
            return newState8;

        case TASK_DELETE:
            let newState8a = {...state};
            Object.keys(newState8a.columns).forEach(function (key, index) {
                newState8a.columns[key].taskIds = newState8a.columns[key].taskIds.filter(function (taskId) {
                    return taskId !== action.payload
                })
            });
            return newState8a;

        case COLUMN_UPDATE:
            console.log("action_column", action.payload)
            let newState81 = {...state};
            newState81.columns[action.payload.id] = action.payload;
            return newState81;

        case TASK_MOVE:
            console.log("newState", action.payload)
            const {destination, source, draggableId, type} = action.payload.result;
            console.log("destination", destination)

            if (!destination) {
                return {...state}
            }
            if (
                destination.droppableId === source.droppableId &&
                destination.index === source.index
            ) {
                return {...state}
            }

            if (type === 'column') {
                const newColumnOrder = Array.from(state.columnOrder)
                newColumnOrder.splice(source.index, 1)
                newColumnOrder.splice(destination.index, 0, draggableId)
                const newState1 = {
                    ...state,
                    columnOrder: newColumnOrder
                }
                return newState1
            }

            const start = state.columns[source.droppableId]
            const finish = state.columns[destination.droppableId]

            if (start === finish) {
                const newTaskIds = Array.from(start.taskIds)
                newTaskIds.splice(source.index, 1)
                newTaskIds.splice(destination.index, 0, draggableId)
                const newColumn = {
                    ...start,
                    taskIds: newTaskIds
                }
                const newState2 = {
                    ...state,
                    columns: {
                        ...state.columns,
                        [newColumn.id]: newColumn
                    }
                }
                return newState2
            }


            // moving from one list to another
            const startTaskIds = Array.from(start.taskIds);
            startTaskIds.splice(source.index, 1)
            const newStart = {
                ...start,
                taskIds: startTaskIds
            }

            const finishTaskIds = Array.from(finish.taskIds);
            finishTaskIds.splice(destination.index, 0, draggableId)
            const newFinish = {
                ...finish,
                taskIds: finishTaskIds
            }

            const newState3 = {
                ...state,
                columns: {
                    ...state.columns,
                    [newStart.id]: newStart,
                    [newFinish.id]: newFinish
                }
            }
            return newState3;

        default:
            return state;
    }

}
