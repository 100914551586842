import React from 'react';
import {connect} from 'react-redux';
import {closeModal} from './modalActions'
import Modal from 'react-modal';
import EditTaskComponent from "./EditTaskComponent";
import EditImageComponent from "./EditImageComponent";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

const actions = {
    closeModal
}

class EditTaskModal extends React.Component {

    constructor() {
        super();

        this.state = {
            modalIsOpen: true
        };

        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentWillMount() {
        Modal.setAppElement('body');
    }

    openModal() {
        this.setState({modalIsOpen: true});
    }

    afterOpenModal() {
        this.subtitle.style.color = '#f00';
    }

    closeModal() {
        this.props.closeModal()
    }

    render() {
        return (
            <div>
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <h2 ref={subtitle => this.subtitle = subtitle}>Edit Cell</h2>

                    {(this.props.currentTasks.tasks[this.props.currentModals.modalProps.taskId].type === 'markdown') &&
                        <EditTaskComponent taskId={this.props.currentModals.modalProps.taskId}
                                           onCancel={this.closeModal}/>
                    }

                    {(this.props.currentTasks.tasks[this.props.currentModals.modalProps.taskId].type === 'image') &&
                        <EditImageComponent taskId={this.props.currentModals.modalProps.taskId}
                                            onCancel={this.closeModal}
                                            type={"image"}/>
                    }

                    {(this.props.currentTasks.tasks[this.props.currentModals.modalProps.taskId].type === 'video') &&
                        <EditImageComponent taskId={this.props.currentModals.modalProps.taskId}
                                            onCancel={this.closeModal}
                                            type={"video"}/>
                    }
                </Modal>
            </div>
        );
    }

};

const mapStateToProps = (state) => ({
    currentModals: state.modals,
    currentTasks: state.task
});

export default connect(mapStateToProps, actions)(EditTaskModal);
