import React, {Component} from 'react'
import {
    Col,
    Row
} from "reactstrap";
import styled from "styled-components";

const Cent = styled.div`
    display: flex
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${props => props.darkText ? "orange" : `${props.theme.lightAccent}`};
`;

class Footer extends Component {
    render() {
        return (
            <Row>
                <Col sm={12}>
                    <Cent darkText={this.props.darkText}>
                        &copy; 2022 Jonathan Leahy | 31-03-22 17-00
                    </Cent>
                </Col>
            </Row>
        )
    }
}

export default (Footer);
