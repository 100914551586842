import React, {Fragment} from 'react';
import {openModal} from "./Modals/modalActions";
import * as userActions from "../actions/userActions";
import * as stuffActions from "../actions/stuffActions";
import * as taskActions from "../plugins/NTWys2/Components/actions/taskActions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {withRouter} from "react-router-dom";
import ModalManager from "./Modals/ModalManager";
import NavEditBar from "./NavEditBar";
import NTWys2 from "../plugins/NTWys2/Components/NTWys2";
import {Container} from "reactstrap";

class EditPage extends React.Component {

    componentDidMount() {
        this.props.taskActions.editPage(this.props.matchParams.params.pagename);
    }

    render() {
        const showEditor = this.props.stuff[0].showEditor
        return (
            <Fragment>
                <Container>
                <ModalManager/>
                <NavEditBar/>
                <br/>
                <br/>
                <br/>
                <br/>
                <NTWys2 currentTsks={this.props.currentTasks} showEditor={showEditor}
                       bImagesOnly={true}/>
                </Container>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    currentTasks: state.task,
    stuff: state.stuff,
});

function mapDispatchToProps(dispatch) {
    return {
        openModal: bindActionCreators(openModal, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
        stuffActions: bindActionCreators(stuffActions, dispatch),
        taskActions: bindActionCreators(taskActions, dispatch),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditPage));
