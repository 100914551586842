import {TASK_UPDATE, TASK_DELETE, TASK_MOVE, TASKS_RECEIVE, COLUMN_UPDATE, TASK_ADDROW, TASK_ADDCELL} from "./allTasks";
import AuthHelperMethods from "../../../../components/AuthHelperMethods";
import {convertNTStoDaDFormat} from "../../dataprocessing/convertNTStoDaDFormat";
import {toastr} from "react-redux-toastr";
import {WysData} from "../../data/personalVariable";
import {prodDev} from "../../../../library/prodDev";
import * as axios from "axios";

export const saveTask = (attributes) => {
    return {
        type: TASK_UPDATE,
        payload: attributes,
    }
}

export const deleteTask = (attributes) => {
    return {
        type: TASK_DELETE,
        payload: attributes,
    }
}

export const saveColumn = (attributes) => {
    return {
        type: COLUMN_UPDATE,
        payload: attributes,
    }
}

export const moveTask = (results) => {
    return {
        type: TASK_MOVE,
        payload: results,
    }
}

export function receiveStuff(data) {
    let convertData = new convertNTStoDaDFormat()
    data = convertData.convert()
    return {type: TASKS_RECEIVE, stuff: data};
}

export function fetchProductsSuccess(data) {
    return {type: TASKS_RECEIVE, stuff: data};
}

export function fetchProductsFailure(data) {
    return {type: TASKS_RECEIVE, stuff: ""};
}

export async function getProductsNT(pagename) {
    try {
        let Auth = new AuthHelperMethods();
        let endURL = prodDev.getUrl()
        let urell = endURL + "/portfolio/" + pagename
        const response = await Auth.fetch(urell)
        const json = await response.data;
        json.filename = pagename
        return json
    } catch (e) {
    }
}

export async function getAPI(pagename) {
    try {
        let jsons = new WysData()
        return jsons.getStores()
    } catch (e) {
    }
}

async function writeProductsNT(pagename, pagedata) {
    try {
        let Auth = new AuthHelperMethods();
        let endURL = prodDev.getUrl()
        let urell = endURL + '/portfolio/save/' + pagename
        return await Auth.post(urell, pagedata)
    } catch (e) {
    }
}

async function printOutPage(pagename) {

    try {
        console.log("ok")

        axios({
            url: prodDev.getUrl() + "/pdfpage/" + pagename,
            method: 'post',
            responseType: 'blob',
            data: {
                projectId: "this.state.Project.id"
            }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'report.pdf');
            document.body.appendChild(link);
            link.click();
        });

    } catch (e) {
        console.log("no" + e)
    }
}

export function fetchProductsNT(pagename) {
    return dispatch => {
        return getProductsNT(pagename)
            .then(json => {
                dispatch(fetchProductsSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(fetchProductsFailure(error))
            });
    };
}

export function api(pagename) {
    return dispatch => {
        return getAPI(pagename)
            .then(json => {
                dispatch(fetchProductsSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(fetchProductsFailure(error))
            });
    };
}

export function saveProductsNT(pagename, pagedata) {
    return dispatch => {
        return writeProductsNT(pagename, pagedata)
            .then(() => {
                    toastr.success("Page Saved")
                }
            )
            .catch(error => {
                dispatch(fetchProductsFailure(error))
            });
    };
}

export function printPageNT(pagename) {
    return dispatch => {
        return printOutPage(pagename)
            .then(() => {
                    toastr.success("Page Printed")
                }
            )
            .catch(error => {
                dispatch(fetchProductsFailure(error))
            });
    };
}

export function addRow() {
    return {
        type: TASK_ADDROW,
        payload: {},
    }
}

export function addCell(columnId) {
    return {
        type: TASK_ADDCELL,
        columnId: columnId,
    }
}

export function editPage(page) {
    return (dispatch) => {
        switch (page) {
            case 'directory':
                dispatch(fetchProductsNT('directory'))
                break;
            case 'portfolio':
                dispatch(fetchProductsNT('portfolio'))
                break;
            case 'cymap':
                dispatch(fetchProductsNT('cymap'))
                break;
            case 'github':
                dispatch(fetchProductsNT('github'))
                break;
            case 'newtimesystems':
                dispatch(fetchProductsNT('newtimesystems'))
                break;
            case 'bugtrack':
                dispatch(fetchProductsNT('bugtrack'))
                break;
            case 'firefoxextension':
                dispatch(fetchProductsNT('firefoxextension'))
                break;
            case 'ttf':
                dispatch(fetchProductsNT('ttf'))
                break;
            case 'editor':
                dispatch(fetchProductsNT('editor'))
                break;
            case 'smart':
                dispatch(fetchProductsNT('smart'))
                break;
            case 'vantage':
                dispatch(fetchProductsNT('vantage'))
                break;
            case 'workflow':
                dispatch(fetchProductsNT('workflow'))
                break;
            case 'wikiloca':
                dispatch(fetchProductsNT('wikiloca'))
                break;
            case 'personal':
                dispatch(fetchProductsNT('personal'))
                break;
            case 'cv':
                dispatch(fetchProductsNT('cv'))
                break;
            case 'ability':
                dispatch(fetchProductsNT('ability'))
                break;
            case 'propeller':
                dispatch(fetchProductsNT('propeller'))
                break;
            case 'ci':
                dispatch(fetchProductsNT('ci'))
                break;
            case 'quote':
                dispatch(fetchProductsNT('quote'))
                break;
            case 'api':
                dispatch(api('api'))
                break;
            case 'projectstatus':
                dispatch(fetchProductsNT('projectstatus'))
                break;
            case 'softwaredevelopment':
                dispatch(fetchProductsNT('softwaredevelopment'))
                break;
            case 'manuala':
                dispatch(fetchProductsNT('manuala'))
                break;
            case 'madproposal':
                dispatch(fetchProductsNT('madproposal'))
                break;
            case 'madtheproposal':
                dispatch(fetchProductsNT('madtheproposal'))
                break;
            case 'training':
                dispatch(fetchProductsNT('training'))
                break;
            default:
                console.log("unknown page")
                break
        }
    }
}

export function savePage(page, pagedata) {
    return (dispatch) => {
        dispatch(saveProductsNT(page, pagedata))
    }

}

export function printPage(page) {
    return (dispatch) => {
        dispatch(printPageNT(page))
    }
}
