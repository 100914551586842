import React, {Fragment} from 'react';
import Login from "./Modals/Login";
import {Col, Row} from "reactstrap";
import styled from "styled-components";
import Footer from "./Footer";

const Container1 = styled.div`
    background-size: ${props => props.backgroundSize};
    background: linear-gradient( rgba(0, 0, 0, 1), rgba(255, 255, 255, 0) 85vh, rgba(0, 0, 0, 1) 95vh ), url('images/67398514-shot-of-young-asian-female-student-sitting-at-table-and-writing-on-notebook-young-female-student-stu.jpg');
    height: 95vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`;

const Container2 = styled.div`
    padding: 1rem 1rem;
`;

const Cent = styled.div`
    display: flex
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const H1Light = styled.h1`
    color: ${props => props.theme.lightAccent};
`;

const H2Light = styled.h2`
    color: ${props => props.theme.lightAccent};
`;

const PLight = styled.p`
    color: ${props => props.theme.lightAccent};
`;

class HomePage extends React.Component {

    render() {
        return (
            <Fragment>
                <Container1>
                    <Container2>
                        <Row>
                            <Col sm={1} md={1}><br/></Col>
                            <Col sm={10} md={10}>
                                <Row>
                                    <Col sm={10} md={10}>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <H1Light>WYS Editor Content Management</H1Light>
                                        <H2Light><a href="http://www.newtimesystems.com">Newtime Systems</a></H2Light>
                                    </Col>
                                </Row>
                                <br/>
                                <br/>
                                <br/>
                                <Row>
                                </Row>
                                <Row>
                                    <Col xs={8} sm={6} md={8}>
                                        <PLight>Easy Page Editing and updating, directly from your web browser.</PLight>
                                        <PLight>Visual drag and drop allows content to be placed and moved easily and
                                            quickly.</PLight>
                                        <PLight>Make your content available directly to the web with a couple of mouse
                                            clicks
                                            with your own shortcut URL.</PLight>
                                        <PLight>Register for a new account now and have your content on the internet in
                                            moments!</PLight>
                                    </Col>
                                    <Col xs={8} sm={6} md={4}>
                                        <br/>
                                        <br/>
                                        <Login/>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={1} md={1}><br/></Col>
                            <Col sm>
                                <br/>
                                <br/>
                                <br/>
                                <Cent>
                                    <PLight>
                                        <i className="far fa-file-plus"/>
                                        &nbsp;
                                        Create a Page
                                    </PLight>
                                </Cent>
                            </Col>
                            <Col sm>
                                <br/>
                                <br/>
                                <br/>
                                <Cent>
                                    <PLight>
                                        <i className="far fa-share"/>
                                        &nbsp;
                                        Share Link
                                    </PLight>
                                </Cent>
                            </Col>
                            <Col sm>
                                <br/>
                                <br/>
                                <br/>
                                <Cent>
                                    <PLight>
                                        <i className="far fa-external-link"/>
                                        &nbsp;
                                        Integrate
                                    </PLight>
                                </Cent>
                            </Col>
                        </Row>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <Footer darkText={false}/>
                        <br/>
                    </Container2>
                </Container1>
            </Fragment>
        )
    }
}

export default (HomePage);
