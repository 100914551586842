import React from 'react';
import { connect } from 'react-redux';
import { closeModal } from './modalActions'

import * as userActions from '../../actions/userActions';

import Modal from 'react-modal';
import styled from "styled-components";
import AuthHelperMethods from "../../components/AuthHelperMethods";
import {bindActionCreators} from "redux";
import {toastr} from "react-redux-toastr";
import {Formik} from "formik";
import {withRouter} from "react-router-dom";

const Across = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem 1rem 1rem 1rem;
    width: 250px;
`;

const TextInput = styled.input`
    border-radius: .3em;
    border: none;
    margin: 10px auto;
    padding: 5px 15px;
    border: 1px grey solid;
`;

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    }
};

class LoginModal extends React.Component {

    Auth = new AuthHelperMethods();

    constructor(props) {
        super(props);

        this.state = {
            modalIsOpen: true,
            username: "jon",
            password: "ok",
            access: ""
        };

        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentWillMount() {
        Modal.setAppElement('body');
    }

    openModal() {
        this.setState({modalIsOpen: true});
    }

    afterOpenModal() {
        this.subtitle.style.color = '#f00';
    }

    closeModal() {
        this.props.closeModal()
    }

    /* Fired off every time the use enters something into the input fields */
    _handleChange = (e) => {
        this.setState(
            {
                [e.target.name]: e.target.value
            }
        )
    }

    handleFormSubmit = (e) => {
        e.preventDefault();
        /* Here is where all the login logic will go. Upon clicking the login button, we would like to utilize a login method that will send our entered credentials over to the server for verification. Once verified, it should store your token and send you to the protected route. */
    }

    render() {
        return (
            <Modal
                isOpen={this.state.modalIsOpen}
                onAfterOpen={this.afterOpenModal}
                onRequestClose={this.closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >

                <Across>
                    <div>
                        <h2 ref={subtitle => this.subtitle = subtitle}>Sign In</h2>

                            <div>
                                <Formik

                                    initialValues={ {username: '', password: '' }}

                                    onSubmit={(values, actions) => {
                                        this.Auth.login(values.username, values.password)
                                            .then(res => {
                                                if (res === false) {
                                                    console.log("bad")
                                                } else {
                                                    this.props.userActions.setUserName('loggedin')
                                                    toastr.success("You are now signed in");
                                                    this.props.closeModal()
                                                    this.props.history.replace("/")
                                                }
                                            })
                                            .catch(err => {
                                                if (err.message === "Unauthorized") {
                                                    toastr.error("Sorry those credentials don't exist!");
                                                }
                                            });
                                        }
                                    }

                                    render={props => (
                                        <form onSubmit={props.handleSubmit}>

                                            <TextInput
                                                type="text"
                                                placeholder={"Username"}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                value={props.values.username}
                                                name="username"
                                            />

                                            <TextInput
                                                type="password"
                                                placeholder={"Password"}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                value={props.values.password}
                                                name="password"
                                            />
                                            {props.errors.name && <div id="feedback">{props.errors.name}</div>}
                                            <br/>
                                            <br/>
                                            <button className="btn btn-primary" type="submit">Submit</button>
                                        </form>
                                    )}

                                />
                            </div>
                    </div>
                </Across>
            </Modal>
        );
    }

};

function mapStateToProps(state) {
    return {
        user: state.user
    };
}

function mapDispatchToProps(dispatch) {
    return {
        closeModal: bindActionCreators(closeModal, dispatch),
        userActions: bindActionCreators(userActions, dispatch)
    };
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginModal));
