import React, {Fragment} from 'react'
import styled from 'styled-components'
import Task from './task'
import {Droppable, Draggable} from "react-beautiful-dnd";
import {openModal} from "../../../components/Modals/modalActions";
import {connect} from "react-redux";
import Markdown from "markdown-to-jsx";
import {addCell} from "./actions/taskActions";

const Container = styled.div`
    margin: 8px;
    border-radius: 5px;
    width: 100%
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: inherit;
`;

const TaskList = styled.div`
    transition: background-color 0.2s ease;
    background-color: ${props => (props.isDraggingOver ? 'skyblue' : 'inherit')};
    flex-grow: 1;
    min-height: 25px;
    display: flex;
`;

const NextTo = styled.div`
    display: flex;
    flex-direction: col;
    justify-content: left;
    align-items: center;
`

const Surrounding = styled.div`
    // background-color: yellow;    
`

const Edit = styled.button`
    opacity: .2;
    width: 40px;
    height: 40px;
    margin-right: 8px;
    border-radius: 2px;
    min-width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: skyblue;           
    visibility: ${props => (props.showEditor ? 'visible' : 'hidden')};
`

const Handle = styled.div`
    opacity: .2;
    width: 40px;
    height: 40px;
    border-radius: 2px;
    min-width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: skyblue;           
    visibility: ${props => (props.showEditor ? 'visible' : 'hidden')};
`


const actions = {openModal, addCell}

class Column extends React.Component {

    render() {

        const {openModal} = this.props;
        const {addCell} = this.props;

        const showEditor = this.props.showEditor

        if (this.props.column.id[0] === '-' && !showEditor) {
            return null
        }

        return (

            <Draggable draggableId={this.props.column.id} index={this.props.index}>
                {provided => (
                    <Container
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                    >
                        <Surrounding>

                        <NextTo>
                            {(showEditor || this.props.column.title !== '') &&
                            <div>
                                <Markdown>
                                    {this.props.column.title}
                                </Markdown>
                            </div>
                            }

                            {(showEditor && this.props.column.id[0] !== '-') &&
                            <Fragment>
                                <Edit className="btn btn-primary" type="button"
                                      data-target="#deliverable-add" data-toggle="collapse"
                                      aria-expanded="false" aria-controls="deliverable-add"
                                      showEditor={showEditor} taskid={this.props.column.id}
                                      onClick={() => openModal('EditColumnModal', {columnId: this.props.column.id}
                                      )}
                                >
                                    <i className="far fa-pencil-alt"></i>
                                </Edit>
                                <Handle {...provided.dragHandleProps} showEditor={showEditor}>
                                    <i className="far fa-arrows"></i>
                                </Handle>
                                &nbsp;
                                <Edit className="btn btn-primary" type="button"
                                      data-target="#deliverable-add" data-toggle="collapse"
                                      aria-expanded="false" aria-controls="deliverable-add"
                                      showEditor={showEditor} taskid={this.props.column.id}
                                      onClick={() => addCell(this.props.column.id)}
                                >
                                    <i className="far fa-angle-right"></i>
                                </Edit>
                            </Fragment>
                            }

                        </NextTo>

                        <Droppable
                            droppableId={this.props.column.id}
                            direction="horizontal"
                            type="task"
                        >
                            {(provided, snapshot) => (
                                <TaskList
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    isDraggingOver={snapshot.isDraggingOver}
                                >
                                    <Fragment>
                                    {this.props.tasks.map((task, index) => (
                                        <Task key={task.id + task.content} task={task} index={index}
                                              showEditor={showEditor}
                                              bImagesOnly={this.props.bImagesOnly}
                                        />
                                    ))}
                                    {provided.placeholder}
                                    </Fragment>
                                </TaskList>
                            )}
                        </Droppable>

                        </Surrounding>
                    </Container>
                )}
            </Draggable>
        )

    }

}

export default connect(null, actions)(Column);
