export class WysData {

    constructor() {
        this.stores = datas
    }

    getStores() {
        // return this.stores
        this.newStore()

        this.stores['tasks']['task-1']['content'] = `
### Solar System Exploration

[ ] Mercury

[x] Venus

[x] Earth (Orbit/Moon)

[x] Mars

[ ] Jupiter

[ ] Saturn

[ ] Uranus

[ ] Neptune

[ ] Comet Haley
`
        return this.stores
    }

    newStore() {
        this.stores = {
            'tasks': {
                "task-1": {
                    "id": "task-1",
                    "type": "markdown",
                    "content": "Editor Mode"
                },
                "task-2": {
                    "id": "task-2",
                    "type": "markdown",
                    "content": "Editor Mode"
                },
            },
            'columns': {
                "column-1": {
                    "id": "column-1",
                    "title": "S1ystem",
                    "taskIds": [
                        "task-1"
                    ]
                },
            },
            "columnOrder": [
                "column-1",
            ],
        }
    }

}

const datas = {
    "tasks": {
        "-task-1": {
            "id": "-task-1",
            "type": "system-0",
            "content": "Editor Mode"
        },
        "task-3a": {
            "id": "task-3a",
            "type": "markdown",
            "content": "\n## Client Requirements\n\nThe Newtime Systems website has been designed as a way of selling my services and showcasing the range of technologies I work with.\n\nAll companies need a high quality website these days as customers generally use as a first port of call when reviewing potential solutions.\n\nThe system is designed as a full responsive website so works well on desktop, tablet and module.\n\nThis website is based on a bootstrap template which allowed me to concentrate on the interactive experence.\n\nBuilt using the latest technologies and providing a rich visual layout along with a serphicated interacted user expreence I am happy that it meets that brief.\n\nAs I build software it is important to allow clients to stay up to date on the progress of these projects.\n\nThe system provides a secure login facility which then allows that user to view their current project details, progress and status's.\n\nThis helps to keep the client up to date on development progress."
        },
        "task-3b": {
            "id": "task-3b",
            "type": "markdown",
            "content": "\n## Client Requirements\n\nThe 1122 Newtime Systems website has been designed as a way of selling my services and showcasing the range of technologies I work with.\n\nAll companies need a high quality website these days as customers generally use as a first port of call when reviewing potential solutions.\n\nThe system is designed as a full responsive website so works well on desktop, tablet and module.\n\nThis website is based on a bootstrap template which allowed me to concentrate on the interactive experence.\n\nBuilt using the latest technologies and providing a rich visual layout along with a serphicated interacted user expreence I am happy that it meets that brief.\n\nAs I build software it is important to allow clients to stay up to date on the progress of these projects.\n\nThe system provides a secure login facility which then allows that user to view their current project details, progress and status's.\n\nThis helps to keep the client up to date on development progress."
        },
        "task-9": {
            "id": "task-9",
            "type": "markdown",
            "content": "A new system has been delivered and was operating correctly and smoothly. The system was hold in the cloud, but the cloud provider only provided weekly backups.\n\nWeekly backups were insufficient as the data was updated all day every day.\n\nAny solution provided would need to be reported to the end user as the end user would and could report back if there was a brake in the backup system.\n"
        }
    },
    "columns": {
        "-column-1": {
            "id": "-column-1",
            "title": "System",
            "taskIds": [
                "-task-1"
            ]
        },
        "column-1b": {
            "id": "column-1b",
            "title": "",
            "taskIds": [
                "task-3a",
                "task-3b"
            ]
        },
        "column-4": {
            "id": "column-4",
            "title": "System Information",
            "taskIds": [
                "task-9"
            ]
        }
    },
    "columnOrder": [
        "-column-1",
        "column-1b",
        "column-4"
    ],
    "filename": "personal"
}
