
import {STUFF_RECEIVE, STUFF_UPDATE} from "../plugins/NTWys2/Components/actions/allTasks";
import AuthHelperMethods from "../components/AuthHelperMethods";

export async function stuffReceive(data) {
    try {
        let Auth = new AuthHelperMethods();

        let endURL = "https://api.jonathanleahy.co.uk";
        if (process.env.NODE_ENV !== 'production') {
            endURL = "http://localhost:3000";
        }
        const response = await Auth.fetch(endURL + `/directory`)
        const json = await response.data;
        return {type: STUFF_RECEIVE, stuff: json};
    } catch (e) {
        console.log("no" + e)
    }
}

export const stuffUpdate = (attributes) => {
    return {
        type: STUFF_UPDATE,
        payload: attributes,
    }
}
