import React, {Fragment, Component} from 'react'
import {withRouter} from "react-router-dom";
import {
    Col,
    Container,
    Nav,
    Navbar, NavbarBrand,
    NavItem,
    NavLink,
    Row
} from "reactstrap";
import * as stuffActions from "../actions/stuffActions";
import * as taskActions from "../plugins/NTWys2/Components/actions/taskActions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import AuthHelperMethods from "../components/AuthHelperMethods";
import {openModal} from "../components/Modals/modalActions";
import * as userActions from "../actions/userActions";

class NavEditBar extends Component {

    state = {}
    setEditorMode = () => {
        this.props.stuffActions.stuffUpdate({showEditor: true})
    }

    signOut() {
        this.props.history.push("/");
    }

    setViewMode = () => {
        this.props.stuffActions.stuffUpdate({showEditor: false})
    }

    printOut = (thePage) => {
        this.props.taskActions.printPage(thePage);
    }

    save = () => {
        const FileSaver = require('file-saver');
        const blob = new Blob([JSON.stringify(this.props.stuff, null, 2)], {type: "text/plain;charset=utf-8"});
        FileSaver.saveAs(blob, "hello world.txt");
    }

    reset = () => {
        this.props.taskActions.fetchTheStuff();
    }

    editPage = (thePage) => {
        this.props.taskActions.editPage(thePage);
    }

    savePage = (thePage, pagedata) => {
        this.props.taskActions.savePage(thePage, pagedata);
    }

    render() {

        const Auth = new AuthHelperMethods()

        let title = ""
        if (Auth.loggedIn() && (this.props.currentTasks.filename)) {
            title = `WYS-Editor: ${this.props.currentTasks.filename}`
        } else {
            title = 'WYS-Editor'
        }

        return (

            <Navbar fixed="top" color="light" light expand="xs" className="border-bottom border-gray bg-white"
                    style={{height: 80}}>

                <Container>
                    <Row noGutters className="position-relative w-100 align-items-center">

                        {Auth.loggedIn() &&
                            <NavItem className="d-flex align-items-center">
                                <NavLink className="font-weight-bold" onClick={() => {
                                    this.signOut()
                                }} href="#">
                                    <i className="far fa-arrow-left"></i>
                                    &nbsp;
                                    Back
                                </NavLink>
                            </NavItem>
                        }

                        <Col className="d-none d-lg-flex justify-content-end">
                            <NavbarBrand className="d-inline-block p-0" href="/" style={{width: 80}}>
                                {/*<img src={logo} alt="logo" className="position-relative img-fluid"/>*/}
                                {title}
                            </NavbarBrand>
                        </Col>

                        {Auth.loggedIn() &&
                            <Col className="d-flex justify-content-xs-start ">
                                <Nav className="mrx-auto" navbar>
                                </Nav>
                            </Col>
                        }

                        {Auth.loggedIn() &&
                            <Fragment>

                                <NavItem className="d-flex align-items-center">
                                    <NavLink className="font-weight-bold" onClick={() => {
                                        this.printOut(this.props.currentTasks.filename)
                                    }} href="#">
                                        <i className="far fa-print"></i>
                                        &nbsp;
                                        Print</NavLink>
                                </NavItem>

                                <NavItem className="d-flex align-items-center">
                                    <NavLink className="font-weight-bold" onClick={() => {
                                        this.setViewMode()
                                    }} href="#">
                                        <i className="far fa-eye"></i>
                                        &nbsp;
                                        View Mode</NavLink>
                                </NavItem>

                                <NavItem className="d-flex align-items-center">
                                    <NavLink className="font-weight-bold" onClick={() => {
                                        this.setEditorMode()
                                    }} href="#">
                                        <i className="far fa-pencil-alt"></i>
                                        &nbsp;
                                        Editor Mode
                                    </NavLink>
                                </NavItem>

                                <NavItem className="d-flex align-items-center">
                                    <NavLink className="font-weight-bold" onClick={() => {
                                        this.savePage(this.props.currentTasks.filename, JSON.stringify(this.props.currentTasks))
                                    }} href="#">
                                        <i className="far fa-save"></i>
                                        &nbsp;
                                        Save
                                    </NavLink>
                                </NavItem>

                            </Fragment>
                        }
                    </Row>
                </Container>
            </Navbar>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    currentTasks: state.task,
    stuff: state.stuff,
});

function mapDispatchToProps(dispatch) {
    return {
        openModal: bindActionCreators(openModal, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
        stuffActions: bindActionCreators(stuffActions, dispatch),
        taskActions: bindActionCreators(taskActions, dispatch),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavEditBar));
