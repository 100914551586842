import React, {Component, Fragment} from 'react';
import styled from 'styled-components'
import ReactMediumImg from 'react-medium-zoom'

const Container1 = styled.div`
    width: 100%;
    height: 100%;
    border: 1rem solid transparent;
    background-image: url(${props => props.src});
    background-size: ${props => props.backgroundSize};
    background-repeat: no-repeat;
    background-position: center center;
    display:flex;
    align-items: center;
    justify-content: center;
    `;

class Imager extends Component {

    constructor(props) {
        super(props);
        this.state = {
            image: this.props.src,
            width: this.props.width,
            backgroundSize: this.props.backgroundSize
        }
    }

    render() {

        let bShowZoom = this.props.bShowzoom
        if (this.props.bImagesOnly === false) {
            bShowZoom = false
        }

        return (

            <Fragment>

                {(bShowZoom) &&
                    <Container1>
                        <ReactMediumImg
                            width={this.state.width}
                            src={this.state.image}
                            backgroundsize={this.state.backgroundSize}
                        />
                    </Container1>
                }

                {(!bShowZoom) &&
                    <Container1>
                        <img
                            width='100%'
                            src={this.state.image}
                            backgroundsize={this.state.backgroundSize}
                            alt={"#"}
                        />
                    </Container1>
                }

            </Fragment>
        )
    }

}

export default Imager;
