import React from 'react';

class stuffList extends React.Component {
    componentWillMount() {
    }

    renderData(item) {
    }

    render() {
        return null
    }
}

export default stuffList;
