import React, {Fragment} from 'react';
import styled from "styled-components";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import Column from "./column";
import {moveTask} from "./actions/taskActions";
import {connect} from "react-redux";

const Container = styled.div`
    display: flex;
    flex-direction: column;    
    justify-content: center;
    align-items: center;
    width: 100%;
    
    // background-color: yellow;
            
    h1   {
      margin-top: 2rem;
      margin-left: 0rem;
      margin-bottom: 2rem;
      margin-right: 0rem;
    }

    h2   {
      margin-top: 1rem;
      margin-bottom: .5rem;
    }

     h3 {
      margin-top: 2rem;
      margin-bottom: 1rem;
    }
  
     h4 {
      margin-top: 1.5rem;
      margin-bottom: 1rem;
    }

     h5, h6 {
      margin-top: 1rem;
      margin-bottom: .5rem;
    }
    
    .mdImage {
        padding: 1rem 1rem;
    }

    .mdImageShapeOutside {
        shape-outside: circle();
    }
`;

const H1center = styled.h1`
    display: block;
    display: flex
    justify-content: center;
    width: 100%;
`;

class NTWys2 extends React.Component {

    onDragEnd = result => {
        this.props.dispatch(
            moveTask({result: result})
        );
    }

    /*
        This is the one to turn into a component!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        react-medium-image can't be used with the editor for some reason
     */
    render() {

        let showEditor = this.props.showEditor

        if (!this.props.currentTsks || !this.props.currentTsks.columnOrder) {
            return (
                <Fragment>
                    <H1center>Loading....</H1center>
                    <br/>
                    <br/>
                    <br/>
                </Fragment>
            )
        }

        //buet-drag and drop in production mode on 11.0.4 breaks
        //11.0.3 works fine
        // so it's wired to 11.0.3

        return (
            <div>
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable
                        droppableId="all-rows"
                        type="column"
                    >
                        {provided => (
                            <Container
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {this.props.currentTsks.columnOrder.map((columnId, index) => {
                                    const column = this.props.currentTsks.columns[columnId];

                                    if (!showEditor) {
                                        if (column.title.match(/Draft/)) {
                                            return null
                                        }
                                    }

                                    const tasks = column.taskIds.map(taskId => this.props.currentTsks.tasks[taskId]);
                                    return (
                                        <Fragment>
                                            <Column key={this.props.currentTsks.filename + column.id}
                                                    column={column}
                                                    tasks={tasks}
                                                    index={index}
                                                    showEditor={showEditor}
                                                    bImagesOnly={this.props.bImagesOnly}/>
                                        </Fragment>
                                    )
                                })
                                }
                                {provided.placeholder}
                            </Container>
                        )
                        }
                    </Droppable>
                </DragDropContext>
            </div>
        )
    }

}

const mapStateToProps = (state) => ({
    currentTasks: state.task,
    stuff: state.stuff,
});

export default connect(mapStateToProps)(NTWys2);
