import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import {
    Col,
    Container,
    Nav,
    Navbar,
    NavItem,
    NavLink,
    Row
} from "reactstrap";
import * as stuffActions from "../actions/stuffActions";
import * as taskActions from "../plugins/NTWys2/Components/actions/taskActions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {toastr} from "react-redux-toastr";
import AuthHelperMethods from "../components/AuthHelperMethods";
import {openModal} from "../components/Modals/modalActions";
import * as userActions from "../actions/userActions";

class NavBar extends Component {

    state = {}

    setEditorMode = () => {
        this.props.stuffActions.stuffUpdate({showEditor: true})
    }

    signOut() {
        const Auth = new AuthHelperMethods()
        this.props.userActions.setUserName('')
        toastr.success("You are now signed out");
        this.props.history.push("/");
        Auth.logout()
    }

    setViewMode = () => {
        this.props.stuffActions.stuffUpdate({showEditor: false})
    }

    save = () => {
        var FileSaver = require('file-saver');
        var blob = new Blob([JSON.stringify(this.props.stuff, null, 2)], {type: "text/plain;charset=utf-8"});
        FileSaver.saveAs(blob, "hello world.txt");
    }

    reset = () => {
        this.props.taskActions.fetchTheStuff();
    }

    editPage = (thePage) => {
        this.props.taskActions.editPage(thePage);
    }

    savePage = (thePage, pagedata) => {
        this.props.taskActions.savePage(thePage, pagedata);
    }

    render() {

        const {openModal} = this.props;
        const Auth = new AuthHelperMethods()

        return (

            <Navbar fixed="top" color="light" light expand="xs" className="border-bottom border-gray bg-white"
                    style={{height: 80}}>

                <Container>
                    <Row noGutters className="position-relative w-100 align-items-center">


                        {Auth.loggedIn() &&
                        <Col className="d-flex justify-content-xs-start ">
                            <h2>WYSEditor</h2>
                        </Col>
                        }

                        {Auth.loggedIn() &&
                        <Col className="d-flex justify-content-xs-start ">
                            <Nav className="mrx-auto" navbar>
                            </Nav>
                        </Col>
                        }

                        {!Auth.loggedIn() &&
                            <NavItem className="d-flex align-items-center">
                                <NavLink className="font-weight-bold" onClick={() => {
                                    openModal('LoginModal', {data: 43})
                                }} href="#">
                                    <i className="far fa-sign-in"></i>
                                    &nbsp;
                                    Sign In
                                </NavLink>
                            </NavItem>
                        }

                        {Auth.loggedIn() &&
                            <NavItem className="d-flex align-items-center">
                                <NavLink className="font-weight-bold" onClick={() => {
                                    this.signOut()
                                }} href="#">
                                    <i className="far fa-sign-out"/>
                                    &nbsp;
                                    Sign Out
                                </NavLink>
                            </NavItem>
                        }
                    </Row>
                </Container>
            </Navbar>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    currentTasks: state.task,
    stuff: state.stuff,
});

function mapDispatchToProps(dispatch) {
    return {
        openModal: bindActionCreators(openModal, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
        stuffActions: bindActionCreators(stuffActions, dispatch),
        taskActions: bindActionCreators(taskActions, dispatch),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps
)(NavBar));
