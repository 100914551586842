import React from 'react'
import {connect} from 'react-redux';
import {Formik} from "formik/dist/index";
import {saveTask} from "../../plugins/NTWys2/Components/actions/taskActions";
import {closeModal} from "./modalActions";

class EditImageComponent extends React.Component {

    /* This is the deliverable so task needs updating to deliverable */
    constructor(props) {
        super(props);
        this.state = {
            taskId: this.props.currentModals.modalProps.taskId,
            task: []
        };
    }

    async componentDidMount() {
        const aTask = {
            taskId: this.props.currentTasks.tasks[this.state.taskId].id,
            content: this.props.currentTasks.tasks[this.state.taskId].content,
            height: this.props.currentTasks.tasks[this.state.taskId].height,
            width: this.props.currentTasks.tasks[this.state.taskId].width,
            backgroundSize: this.props.currentTasks.tasks[this.state.taskId].backgroundSize,
            type: this.props.currentTasks.tasks[this.state.taskId].type
        }

        this.setState({task: aTask});
    }

    onDelete() {
        // this.props.dispatch(
        //     deleteDeliverable(
        //         this.state.deliverableId,
        //     ));
    }

    render() {

        if (this.state.task.length === 0) {
            return null
        }

        return (
            <div style={{width: '600px'}}>

                <Formik

                    initialValues={{
                        taskId: this.state.task.id,
                        content: this.state.task.content,
                        height: this.state.task.height,
                        width: this.state.task.width,
                        backgroundSize: this.state.task.backgroundSize,
                        type: this.state.task.type,
                    }}

                    onSubmit={values => {
                        console.log("-----", JSON.stringify(this.state))
                        this.props.dispatch(
                            saveTask(
                                {
                                    id: this.state.taskId,
                                    type: values.type,
                                    content: values.content,
                                    width: values.width,
                                    height: values.height,
                                    backgroundSize: values.backgroundSize,
                                    bShowZoom: true
                                }
                            )
                        );
                        this.props.dispatch(closeModal())

                    }}

                    // validate={ values => {
                    //     let errors= {}
                    //     if (!values.title) {
                    //         errors.title = "please enter a name"
                    //     }
                    //     if (!values.duration) {
                    //         errors.duration= "please enter a duration"
                    //     }
                    //     return errors
                    // }}

                >
                    {
                        ({handleSubmit, handleChange, values, errors, handleBlur, touched}) => (
                            <form onSubmit={handleSubmit}>

                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Description</label>
                                    <input
                                        className="form-control"
                                        onChange={handleChange}
                                        value={values.content}
                                        name="content"
                                        placeholder="Enter your Image"
                                        onBlur={handleBlur}
                                        rows={15}
                                    />
                                    <small id="emailHelp" className="form-text text-muted">A nice description
                                    </small>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Background Size</label>
                                    <input
                                        className="form-control"
                                        onChange={handleChange}
                                        value={values.backgroundSize}
                                        type="text"
                                        name="backgroundSize"
                                        placeholder="Enter your BackgroundSize"
                                        onBlur={handleBlur}
                                    />
                                    <small id="emailHelp" className="form-text text-muted">BackgroundSize</small>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Width</label>
                                    <input
                                        className="form-control"
                                        onChange={handleChange}
                                        value={values.width}
                                        type="text"
                                        name="width"
                                        placeholder="Enter your Width"
                                        onBlur={handleBlur}
                                    />
                                    <small id="emailHelp" className="form-text text-muted">Width</small>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Height</label>
                                    <input
                                        className="form-control"
                                        onChange={handleChange}
                                        value={values.height}
                                        type="text"
                                        name="height"
                                        placeholder="Enter your height"
                                        onBlur={handleBlur}
                                    />
                                    <small id="emailHelp" className="form-text text-muted">Height</small>
                                </div>

                                <div className="row">
                                    <div className="col-sm-6">
                                        <button type="submit" className="btn btn-primary pull-right">Save</button>
                                        &nbsp;
                                        <button onClick={() => {
                                            this.props.onCancel()
                                        }} className="btn btn-secondary pull-right">Cancel
                                        </button>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="text-right">
                                            <button type="button" className="btn btn-danger" onClick={() => {
                                                this.onDelete()
                                            }}>Delete
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {
                                    errors.title &&
                                    <div style={{color: 'red'}}>
                                        {errors.title}
                                    </div>
                                }
                            </form>
                        )

                    }
                </Formik>

            </div>
        )

    };

}

const mapStateToProps = (state) => ({
    currentModals: state.modals,
    currentTasks: state.task
});


export default connect(mapStateToProps, null)(EditImageComponent);
