import AuthHelperMethods from "./components/AuthHelperMethods";

import {convertNTStoDaDFormat} from "./plugins/NTWys2/dataprocessing/convertNTStoDaDFormat";

export async function stuffReceive(data) {
    try {
        let Auth = new AuthHelperMethods();

        let endURL = "https://api.jonathanleahy.co.uk";
        if (process.env.NODE_ENV !== 'production') {
            endURL = "http://localhost:3000";
        }
        const response = await Auth.fetch(endURL + `/portfolio/directory`)
        const json = await response.data;
        return json;
    } catch (e) {
        console.log("no" + e)
    }
}

export default function initialState() {
    let convertData = new convertNTStoDaDFormat()
    let sendback = convertData.convert()
    return sendback
}
