export const editorPageNF =

    {

        tasks: {
            '-task-1': {
                id: '-task-1',
                type: 'system-0',
                content: `Editor Mode`
            },
            '-task-2': {
                id: '-task-2',
                content: `Editor Mode`
            },
            '-task-3': {
                id: '-task-3',
                content: `Editor Mode`
            },
            '-task-4': {
                id: '-task-4',
                content: `Editor Mode`
            },

            'task-1': {
                id: 'task-1',
                content: `I've aaaa developed and shipped plenty of software projects working in teams and individually. Here are a few of the latest generation of projects I developed or worked on.`
            },
            'task-2': {
                id: 'task-2', type: "markdown", content:
                    `#Loading`
            },

            'task-3': {
                id: 'task-3',
                type: "image",
                content: '/images/access.svg',
                width: "10%",
                height: "100px",
                backgroundSize: "cover",
                bShowZoom: true
            },

        },

        columns: {
            '-column-1': {
                id: '-column-1',
                title: 'System',
                taskIds: ['-task-1'], //, '-task-2', '-task-3', '-task-4'],
            },
            'column-1': {
                id: 'column-1',
                title: '',
                taskIds: ['task-2'],
            },
        },

        columnOrder: ['-column-1', 'column-1',  ],

    }

