import React from 'react';
import {connect} from 'react-redux';
import {closeModal} from './modalActions'
import Modal from 'react-modal';
import EditColumnComponent from "./EditColumnComponent";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

const actions = {
    closeModal
}

class EditColumnModal extends React.Component {

    constructor() {
        super();

        this.state = {
            modalIsOpen: true
        };

        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentWillMount() {
        Modal.setAppElement('body');
    }

    openModal() {
        this.setState({modalIsOpen: true});
    }

    afterOpenModal() {
        this.subtitle.style.color = '#f00';
    }

    closeModal() {
        this.props.closeModal()
    }

    render() {
        return (
            <div>
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >

                    <h2 ref={subtitle => this.subtitle = subtitle}>Edit Cell</h2>

                    <EditColumnComponent columnId={this.props.currentModals.modalProps.columnId} onCancel={this.closeModal} />

                    {this.props.currentModals.modalProps.columnId}

                </Modal>
            </div>
        );
    }

};

const mapStateToProps = (state) => ({
    currentModals: state.modals,
    currentColumns: state.column
});

export default connect(mapStateToProps, actions)(EditColumnModal);
