import React from 'react'
import Markdown from "markdown-to-jsx";
import ItemContainer from "./ItemContainer";
import Imager from "./Imager";
import ItemContainerDark from "./ItemContainerDark";
import SyntaxHighlighter from "react-syntax-highlighter";
import darcula from "react-syntax-highlighter/dist/esm/styles/hljs/darcula";
import ReactPlayer from "react-player";
import styled from "styled-components";
import ImageGallery from "react-image-gallery";
import {prodDev} from "../../../library/prodDev";
import {openModal} from "../../../components/Modals/modalActions";
import {connect} from "react-redux";
import * as userActions from "../../../actions/userActions";
import * as stuffActions from "../../../actions/stuffActions";
import {bindActionCreators} from "redux";
import * as taskActions from "../../../plugins/NTWys2/Components/actions/taskActions";
import {Button} from "reactstrap";

const ItemContainerFull = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 605
    text-align: left;
    border-radius: 4px;
    padding: 0rem 0rem 0rem 0rem;
    width: 100%;
`;
const Wrapped = styled.div`
    padding: 1rem 1rem;
`;

const Border = styled.div`
    img {
        padding: 1rem 1rem;
    }
`;

class TaskContent extends React.Component {

    addNewRow() {
        this.props.taskActions.addRow();
    }

    render() {

        console.log(this.props.showEditor)
        const width = this.props.task.width ? this.props.task.width : '90%'
        const minHeight = this.props.task.height ? this.props.task.height : '50px'

        let url = prodDev.getUrl()

        let rep = this.props.task.content
        rep = rep.replace(/\/images\//g, `${url}/images/`)

        switch (this.props.type) {

            case 'system-0':
                return (
                    <Wrapped>
                        {/*<button type="button" className="btn btn-primary" onClick={() => {*/}
                        {/*    this.addNewRow()*/}
                        {/*}}>*/}
                        {/*    Edit Page Details*/}
                        {/*</button>*/}
                        {/*&nbsp;*/}
                        <button type="button" className="btn btn-primary" onClick={() => {
                            this.addNewRow()
                        }}>
                            Add a New Row
                        </button>
                    </Wrapped>
                )

            case 'markdown':
                const MyButton = ({children, ...props}) => (
                    <Button {...props}>{children}</Button>
                );

                return (
                    <ItemContainerFull widthInPercent={width}
                                       minHeightInPercent={minHeight}
                    >
                        <Border>
                            <Markdown
                                children={rep}
                                options={{forceBlock: true, disableParsingRawHTML: false,
                                    overrides: {
                                    Button: {component: MyButton,},
                                },
                                }}
                            />
                        </Border>
                    </ItemContainerFull>
                )

            case 'steps':
                const images1 = [
                    {
                        original: `${url}/images/newtime-01.png`,
                        thumbnail: `${url}/images/newtime-01.png`
                    },
                    {
                        original: `${url}/images/newtime-02.png`,
                        thumbnail: `${url}/images/newtime-02.png`
                    },
                    {
                        original: `${url}/images/newtime-03.png`,
                        thumbnail: `${url}/images/newtime-03.png`
                    },
                    {
                        original: `${url}/images/newtime-04.png`,
                        thumbnail: `${url}/images/newtime-04.png`
                    },
                    {
                        original: `${url}/images/newtime-05.png`,
                        thumbnail: `${url}/images/newtime-05.png`
                    }
                ]
                return (
                    <ItemContainerFull widthInPercent={width}
                                       minHeightInPercent={minHeight}
                    >
                        <ImageGallery items={images1}/>
                    </ItemContainerFull>
                )

            case 'carousel':

                //const PREFIX_URL = 'https://raw.githubusercontent.com/xiaolin/react-image-gallery/master/static/';

                const images = [
                    {
                        original: 'http://lorempixel.com/1000/600/nature/3/',
                        thumbnail: 'http://lorempixel.com/250/150/nature/3/',
                        description: 'Render custom slides within the gallery'
                    },
                    {
                        original: 'http://lorempixel.com/1000/600/nature/1/',
                        thumbnail: 'http://lorempixel.com/250/150/nature/1/',
                    },
                    {
                        original: 'http://lorempixel.com/1000/600/nature/2/',
                        thumbnail: 'http://lorempixel.com/250/150/nature/2/'
                    },
                    {
                        original: 'http://lorempixel.com/1000/600/nature/3/',
                        thumbnail: 'http://lorempixel.com/250/150/nature/3/'
                    },
                    {
                        original: 'http://lorempixel.com/1000/600/nature/3/',
                        thumbnail: 'http://lorempixel.com/250/150/nature/3/'
                    }
                ]
                return (
                    <ItemContainerFull widthInPercent={width}
                                       minHeightInPercent={minHeight}
                    >
                        <ImageGallery items={images}
                                      showThumbnails={false}
                                      showNav={false}
                                      slideInterval={2500}
                                      showFullscreenButton={false}/>
                    </ItemContainerFull>
                )

            case 'image':
                const src1 = `${url}${this.props.task.content}`
                const backgroundSize = this.props.task.backgroundSize ? this.props.task.backgroundSize : 'cover'
                const bShowZoom = this.props.task.bShowZoom ? this.props.task.bShowZoom : false

                return (
                    <ItemContainer widthInPercent={width}
                                   minHeightInPercent={minHeight}
                    >
                        <Imager src={src1}
                                backgroundSize={backgroundSize}
                                bShowZoom={bShowZoom}
                                bImagesOnly={this.props.bImagesOnly}
                        />

                    </ItemContainer>
                )

            case 'code':
                return (
                    <ItemContainerDark widthInPercent={width} order={this.props.position} style={darcula}>
                        <div style={{flex: 1, width: '100%'}}>
                            <SyntaxHighlighter language="vue" style={darcula}
                                               showLineNumbers={true}
                                               wrapLines={true}
                            >
                                {this.props.content}
                            </SyntaxHighlighter>
                        </div>
                    </ItemContainerDark>
                )

            case 'video':
                const src2 = `${url}${this.props.task.content}`
                return (
                    <ItemContainer widthInPercent={width}
                                   minHeightInPercent={minHeight}
                                   className='player-wrapper'>
                        {!this.props.showEditor &&
                        <ReactPlayer
                            className='react-player'
                            url={src2}
                            width='100%'
                            height='auto'
                            controls={true}
                        />
                        }
                        {this.props.showEditor &&
                        <>
                            <br/>
                            <center>
                                <i className="far fa-video"></i>
                            </center>
                            <br/>
                        </>
                        }
                    </ItemContainer>
                )

            default:
                return (
                    <Markdown options={{forceBlock: true}}>
                        {this.props.content}
                    </Markdown>
                )
        }


    }

}

function mapDispatchToProps(dispatch) {
    return {
        openModal: bindActionCreators(openModal, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
        stuffActions: bindActionCreators(stuffActions, dispatch),
        taskActions: bindActionCreators(taskActions, dispatch),
    };
}

export default connect(null, mapDispatchToProps)(TaskContent);
